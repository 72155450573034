/**
 * @generated SignedSource<<f85155b9ed4bd7241c3a34d73ba850d1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type MilestoneIssuesList$data = {
  readonly id: string;
  readonly isArchived: boolean;
  readonly isDisabled: boolean;
  readonly isLocked: boolean;
  readonly milestone: {
    readonly number: number;
  } | null | undefined;
  readonly name: string;
  readonly owner: {
    readonly login: string;
  };
  readonly search: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly __typename: "Issue";
        readonly id: string;
        readonly number: number;
        readonly " $fragmentSpreads": FragmentRefs<"IssueRow">;
      } | {
        readonly __typename: "PullRequest";
        readonly id: string;
        readonly number: number;
        readonly " $fragmentSpreads": FragmentRefs<"PullRequestRow_pullRequest">;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly issueCount: number;
    readonly " $fragmentSpreads": FragmentRefs<"OpenClosedMilestoneIssues">;
  };
  readonly viewerCanPush: boolean;
  readonly " $fragmentType": "MilestoneIssuesList";
};
export type MilestoneIssuesList$key = {
  readonly " $data"?: MilestoneIssuesList$data;
  readonly " $fragmentSpreads": FragmentRefs<"MilestoneIssuesList">;
};

import MilestoneIssuesListQuery_graphql from './MilestoneIssuesListQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v3 = {
  "kind": "Literal",
  "name": "includeMilestone",
  "value": false
},
v4 = {
  "kind": "Literal",
  "name": "labelPageSize",
  "value": 10
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "number"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "query"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "skip"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": MilestoneIssuesListQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "MilestoneIssuesList",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "aggregations",
          "value": true
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "query",
          "variableName": "query"
        },
        {
          "kind": "Variable",
          "name": "skip",
          "variableName": "skip"
        },
        {
          "kind": "Literal",
          "name": "type",
          "value": "ISSUE_ADVANCED"
        }
      ],
      "concreteType": "SearchResultItemConnection",
      "kind": "LinkedField",
      "name": "search",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "issueCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SearchResultItemEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v2/*: any*/),
                    {
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "fetchRepository",
                          "value": false
                        },
                        (v3/*: any*/),
                        (v4/*: any*/)
                      ],
                      "kind": "FragmentSpread",
                      "name": "IssueRow"
                    }
                  ],
                  "type": "Issue",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v2/*: any*/),
                    {
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "includeGitData",
                          "value": false
                        },
                        (v3/*: any*/),
                        (v4/*: any*/)
                      ],
                      "kind": "FragmentSpread",
                      "name": "PullRequestRow_pullRequest"
                    }
                  ],
                  "type": "PullRequest",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "OpenClosedMilestoneIssues"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "number",
          "variableName": "number"
        }
      ],
      "concreteType": "Milestone",
      "kind": "LinkedField",
      "name": "milestone",
      "plural": false,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "owner",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "login",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isArchived",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerCanPush",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDisabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isLocked",
      "storageKey": null
    }
  ],
  "type": "Repository",
  "abstractKey": null
};
})();

(node as any).hash = "6f841d704a1ed5bf9420830d08a64215";

export default node;
