import {ProjectSymlinkIcon, TriangleDownIcon} from '@primer/octicons-react'
import {ActionList, Button} from '@primer/react'
import {useCallback, useState} from 'react'
import type {SharedListHeaderActionProps} from '../types'
import {BulkProjectPicker, ProjectPickerProjectFragment} from '@github-ui/item-picker/ProjectPicker'
import {graphql, readInlineData, useLazyLoadQuery} from 'react-relay'
import type {AddToProjectsBulkActionQuery} from './__generated__/AddToProjectsBulkActionQuery.graphql'
import type {
  ProjectPickerProject$key,
  ProjectPickerProject$data as Project,
} from '@github-ui/item-picker/ProjectPickerProject.graphql'

type AddToProjectsActionProps = {
  issueIds: string[]
} & SharedListHeaderActionProps

const AddToProjectsQuery = graphql`
  query AddToProjectsBulkActionQuery($ids: [ID!]!) {
    nodes(ids: $ids) {
      ... on Issue {
        id
        projectItemsNext(first: 10) {
          edges {
            node {
              id
              project {
                ...ProjectPickerProject
              }
            }
          }
        }
      }
    }
  }
`
export const AddToProjectsBulkAction = ({disabled = false, nested, ...props}: AddToProjectsActionProps) => {
  const [wasTriggered, setWasTriggered] = useState(false)

  const anchorElement = useCallback(
    (anchorProps: React.HTMLAttributes<HTMLElement>) => {
      const anchorText = 'Project'

      if (nested) {
        return (
          <ActionList.Item disabled={disabled} {...anchorProps} role="menuitem">
            <ActionList.LeadingVisual>
              <ProjectSymlinkIcon />
            </ActionList.LeadingVisual>
            {anchorText}
          </ActionList.Item>
        )
      }

      return (
        <Button
          data-testid={'bulk-add-to-project-button'}
          disabled={disabled}
          leadingVisual={ProjectSymlinkIcon}
          trailingVisual={TriangleDownIcon}
          {...anchorProps}
        >
          {anchorText}
        </Button>
      )
    },
    [disabled, nested],
  )

  if (!wasTriggered) {
    return anchorElement({
      onClick: () => {
        setWasTriggered(true)
      },
    })
  }

  return <LazyBulkProjectPicker disabled={disabled} anchorElement={anchorElement} {...props} />
}

const LazyBulkProjectPicker = ({
  issuesToActOn,
  repositoryId,
  anchorElement,
  useQueryForAction,
  query,
  onCompleted,
  onError,
  nested,
  owner,
  repo,
}: AddToProjectsActionProps & {anchorElement: (props: React.HTMLAttributes<HTMLElement>) => JSX.Element}) => {
  //get selected issues IDs
  const {nodes: updatedIssueNodes} = useLazyLoadQuery<AddToProjectsBulkActionQuery>(
    AddToProjectsQuery,
    {ids: issuesToActOn},
    {fetchPolicy: 'store-or-network'},
  )

  const existingIssueProjects = new Map<string, string[]>()
  const selectedIssuesProjects = (updatedIssueNodes || []).map(node => {
    const nodeId = node?.id
    if (!nodeId) return []

    const projects = (node.projectItemsNext?.edges || []).flatMap(a =>
      // eslint-disable-next-line no-restricted-syntax
      a?.node ? [readInlineData<ProjectPickerProject$key>(ProjectPickerProjectFragment, a.node.project)] : [],
    )

    return (
      projects.map(project => {
        const existingProjects = existingIssueProjects.get(nodeId) || []
        existingProjects.push(project.id)
        existingIssueProjects.set(nodeId, existingProjects)

        return {issueId: nodeId, projectId: project.id, projectNode: project}
      }) ?? []
    )
  })

  const projectAppliedToAll = {} as {[key: string]: Project}
  for (const issueProjects of selectedIssuesProjects) {
    for (const project of issueProjects) {
      if (project.projectId in projectAppliedToAll) continue

      if (
        selectedIssuesProjects.every(otherIssueProjects =>
          otherIssueProjects.some(p => p.projectId === project.projectId),
        )
      ) {
        projectAppliedToAll[project.projectId] = project.projectNode
      }
    }
  }

  return (
    <BulkProjectPicker
      pickerId={'add-to-projects-project-picker'}
      issueIds={issuesToActOn}
      repositoryId={repositoryId}
      readonly={false}
      triggerOpen
      query={query}
      useQueryForAction={useQueryForAction}
      selectedProjects={Object.values(projectAppliedToAll)}
      onCompleted={onCompleted}
      onError={onError}
      nested={nested}
      anchorElement={anchorElement}
      owner={owner}
      repo={repo}
    />
  )
}

try{ AddToProjectsBulkAction.displayName ||= 'AddToProjectsBulkAction' } catch {}
try{ LazyBulkProjectPicker.displayName ||= 'LazyBulkProjectPicker' } catch {}