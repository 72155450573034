import {graphql, useFragment} from 'react-relay'
import {ThreePanesLayout} from '@github-ui/three-panes-layout'
import styles from './RepositoryMilestone.module.css'
import {Suspense} from 'react'
import {usePreloadedQuery, useQueryLoader, type PreloadedQuery} from 'react-relay/hooks'
import type {RepositoryMilestoneQuery} from './__generated__/RepositoryMilestoneQuery.graphql'
import type {UserSettingsOptionConfig} from '@github-ui/issue-create/getSafeConfig'
import {LABELS} from './constants/labels'
import {VALUES} from './constants/values'
import type {RepositoryMilestoneInternal$key} from './__generated__/RepositoryMilestoneInternal.graphql'
import {JobInfoWithSubscription} from '@github-ui/issues-bulk-actions/JobInfoWithSubscription'
import {useLocalStorage} from '@github-ui/use-safe-storage/local-storage'
import {ErrorBoundary} from '@github-ui/react-core/error-boundary'
import {MilestoneError} from './MilestoneError'
import {MilestoneActions} from './MilestoneActions'
import {MilestoneIssuesList} from './MilestoneIssuesList'
import {Heading} from '@primer/react'
import {MilestoneDetail} from './MilestoneDetail'

export const RepositoryMilestonePageQuery = graphql`
  query RepositoryMilestoneQuery(
    $owner: String!
    $name: String!
    $number: Int!
    $first: Int!
    $query: String!
    $skip: Int = 0
  ) {
    repository(owner: $owner, name: $name) {
      ...RepositoryMilestoneInternal @arguments(first: $first, number: $number, query: $query, skip: $skip)
    }
  }
`

export const RepositoryMilestone = ({queryRef}: {queryRef: PreloadedQuery<RepositoryMilestoneQuery>}) => {
  const [pageQueryRef] = useQueryLoader<RepositoryMilestoneQuery>(RepositoryMilestonePageQuery, queryRef)
  if (!pageQueryRef) return null

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <RepositoryMilestoneContent pageQueryRef={pageQueryRef} />
    </Suspense>
  )
}

function RepositoryMilestoneContent({pageQueryRef}: {pageQueryRef: PreloadedQuery<RepositoryMilestoneQuery>}) {
  const pageData = usePreloadedQuery<RepositoryMilestoneQuery>(RepositoryMilestonePageQuery, pageQueryRef)

  if (!pageData.repository) return null

  return <RepositoryMilestoneInternal repository={pageData.repository} />
}

export function RepositoryMilestoneInternal({
  repository,
  optionConfig,
}: {
  repository: RepositoryMilestoneInternal$key
  optionConfig?: UserSettingsOptionConfig
}) {
  const data = useFragment(
    graphql`
      fragment RepositoryMilestoneInternal on Repository
      @argumentDefinitions(
        first: {type: "Int!"}
        number: {type: "Int!"}
        query: {type: "String!"}
        skip: {type: "Int!"}
      ) {
        ...MilestoneActions @arguments(number: $number)
        ...MilestoneIssuesList @arguments(first: $first, number: $number, query: $query, skip: $skip)
        milestone(number: $number) {
          ...MilestoneDetail
          title
        }
      }
    `,
    repository,
  )

  const [bulkJobId, setBulkJobId] = useLocalStorage<string | null>(VALUES.localStorageKeyBulkUpdateIssues, null)

  if (!data.milestone) return null

  return (
    <JobInfoWithSubscription bulkJobId={bulkJobId} setBulkJobId={setBulkJobId}>
      <ThreePanesLayout
        contentAs="div"
        resizeable={false}
        leftPaneWidth="small"
        middlePane={
          <div className={styles.middlePaneWrapper}>
            <ErrorBoundary
              fallback={<MilestoneError title={LABELS.milestoneError} message={LABELS.milestoneErrorMessage} />}
            >
              <MilestoneActions repositoryRef={data} optionConfig={optionConfig} />
              <div className={styles.middlePaneGrid}>
                <Heading as="h3">{data.milestone.title}</Heading>
                <MilestoneDetail milestoneRef={data.milestone} />
                <ErrorBoundary
                  fallback={
                    <MilestoneError title={LABELS.milestoneIssuesError} message={LABELS.milestoneIssuesErrorMessage} />
                  }
                >
                  <MilestoneIssuesList
                    repositoryRef={data}
                    singleKeyShortcutsEnabled={optionConfig?.singleKeyShortcutsEnabled}
                  />
                </ErrorBoundary>
              </div>
            </ErrorBoundary>
          </div>
        }
      />
    </JobInfoWithSubscription>
  )
}

try{ RepositoryMilestone.displayName ||= 'RepositoryMilestone' } catch {}
try{ RepositoryMilestoneContent.displayName ||= 'RepositoryMilestoneContent' } catch {}
try{ RepositoryMilestoneInternal.displayName ||= 'RepositoryMilestoneInternal' } catch {}