/**
 * @generated SignedSource<<7922e7414d649c437ae6f7e0df17344c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type MilestoneDetail$data = {
  readonly closed: boolean;
  readonly description: string | null | undefined;
  readonly descriptionHTML: string | null | undefined;
  readonly dueOn: string | null | undefined;
  readonly progressPercentage: number;
  readonly updatedAt: string;
  readonly " $fragmentType": "MilestoneDetail";
};
export type MilestoneDetail$key = {
  readonly " $data"?: MilestoneDetail$data;
  readonly " $fragmentSpreads": FragmentRefs<"MilestoneDetail">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MilestoneDetail",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "closed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dueOn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "descriptionHTML",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "progressPercentage",
      "storageKey": null
    }
  ],
  "type": "Milestone",
  "abstractKey": null
};

(node as any).hash = "bd3dd4f8725b073fb06fa44bc4baad56";

export default node;
