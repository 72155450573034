export const BUTTON_LABELS = {
  comment: 'Comment',
  create: 'Create',
  cancel: 'Cancel',
  discardChanges: 'Discard changes',
  save: 'Save',
  apply: 'Apply',
  issues: 'Issues',
  addAssignees: 'Add assignees',
  addLabels: 'Add labels',
  addMilestone: 'Add milestone',
  addANewTeam: 'Add a new team',
  newIssue: 'New issue',
  hideDetails: 'Hide details',
  showDetails: 'Show details',
  sendFeedback: 'Give feedback',
  saveView: 'Save view',
  returnToList: 'Return to list',
  setIssueType: 'Issue type',
}
