import {SubNav} from '@primer/react'
import {TagIcon, MilestoneIcon} from '@primer/octicons-react'
import styles from './LabelAndMilestoneNavigation.module.css'

export const LabelAndMilestoneNavigation = ({
  selectedItem,
  ariaLabel,
  rootUrl,
}: {
  selectedItem: 'labels' | 'milestones'
  ariaLabel?: string
  rootUrl: string
}) => {
  const milestonesUrl = `${rootUrl}/milestones`
  const labelsUrl = `${rootUrl}/labels`
  return (
    <SubNav aria-label={ariaLabel} className={styles.subNav}>
      <SubNav.Links>
        <SubNav.Link href={labelsUrl} className={styles.navLink}>
          <TagIcon />
          <span>Labels</span>
        </SubNav.Link>
        <SubNav.Link href={milestonesUrl} className={styles.navLink} selected={selectedItem === 'milestones'}>
          <MilestoneIcon />
          <span>Milestones</span>
        </SubNav.Link>
      </SubNav.Links>
    </SubNav>
  )
}

try{ LabelAndMilestoneNavigation.displayName ||= 'LabelAndMilestoneNavigation' } catch {}