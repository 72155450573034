/**
 * @generated SignedSource<<f75b0cac060dcf2fe695456d06710442>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type RepositoryMilestoneInternal$data = {
  readonly milestone: {
    readonly title: string;
    readonly " $fragmentSpreads": FragmentRefs<"MilestoneDetail">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"MilestoneActions" | "MilestoneIssuesList">;
  readonly " $fragmentType": "RepositoryMilestoneInternal";
};
export type RepositoryMilestoneInternal$key = {
  readonly " $data"?: RepositoryMilestoneInternal$data;
  readonly " $fragmentSpreads": FragmentRefs<"RepositoryMilestoneInternal">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "number",
  "variableName": "number"
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "number"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "query"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "skip"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "RepositoryMilestoneInternal",
  "selections": [
    {
      "args": (v1/*: any*/),
      "kind": "FragmentSpread",
      "name": "MilestoneActions"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        (v0/*: any*/),
        {
          "kind": "Variable",
          "name": "query",
          "variableName": "query"
        },
        {
          "kind": "Variable",
          "name": "skip",
          "variableName": "skip"
        }
      ],
      "kind": "FragmentSpread",
      "name": "MilestoneIssuesList"
    },
    {
      "alias": null,
      "args": (v1/*: any*/),
      "concreteType": "Milestone",
      "kind": "LinkedField",
      "name": "milestone",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MilestoneDetail"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Repository",
  "abstractKey": null
};
})();

(node as any).hash = "c964fadb4ab8a0a17d827c2d6fea0d25";

export default node;
