/**
 * @generated SignedSource<<f45b42c8ce0dc99272688421d37f6ee2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type RepositoryMilestonePageContentInternal$data = {
  readonly " $fragmentSpreads": FragmentRefs<"RepositoryMilestoneInternal">;
  readonly " $fragmentType": "RepositoryMilestonePageContentInternal";
};
export type RepositoryMilestonePageContentInternal$key = {
  readonly " $data"?: RepositoryMilestonePageContentInternal$data;
  readonly " $fragmentSpreads": FragmentRefs<"RepositoryMilestonePageContentInternal">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "number"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "query"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "skip"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "RepositoryMilestonePageContentInternal",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "number",
          "variableName": "number"
        },
        {
          "kind": "Variable",
          "name": "query",
          "variableName": "query"
        },
        {
          "kind": "Variable",
          "name": "skip",
          "variableName": "skip"
        }
      ],
      "kind": "FragmentSpread",
      "name": "RepositoryMilestoneInternal"
    }
  ],
  "type": "Repository",
  "abstractKey": null
};

(node as any).hash = "4b6eb80fa406918ca6a2b7c40c8fef40";

export default node;
