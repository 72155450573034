import {MarkdownViewer} from '@github-ui/markdown-viewer'
import type {SafeHTMLString} from '@github-ui/safe-html'
import {AlertFillIcon} from '@primer/octicons-react'
import {StateLabel, RelativeTime, ProgressBar} from '@primer/react'
import {useFragment} from 'react-relay'
import {graphql} from 'relay-runtime'
import {LABELS} from './constants/labels'
import styles from './RepositoryMilestone.module.css'
import {useMemo} from 'react'
import type {MilestoneDetail$key} from './__generated__/MilestoneDetail.graphql'

type MilestoneDetailProps = {
  milestoneRef: MilestoneDetail$key
}

export function MilestoneDetail({milestoneRef}: MilestoneDetailProps) {
  const currentMilestone = useFragment(
    graphql`
      fragment MilestoneDetail on Milestone {
        closed
        dueOn
        updatedAt
        description
        descriptionHTML
        progressPercentage
      }
    `,
    milestoneRef,
  )

  const formattedDate = currentMilestone.dueOn
    ? new Date(currentMilestone.dueOn).toLocaleDateString('default', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      })
    : null

  const totalOverDue = useMemo(() => {
    if (!currentMilestone || !currentMilestone.dueOn) return null
    const dueOn = new Date(currentMilestone.dueOn)
    const today = new Date()

    if (dueOn > today) return null

    // Set both dates to midnight to avoid partial day errors
    today.setHours(0, 0, 0, 0)
    dueOn.setHours(0, 0, 0, 0)

    const diffTime = today.getTime() - dueOn.getTime()

    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))

    if (diffDays < 30) {
      return `${diffDays} day(s)`
    }

    const diffMonths = Math.floor(diffDays / 30)
    if (diffMonths < 12) {
      return `${diffMonths} month(s)`
    }

    const diffYears = Math.floor(diffMonths / 12)
    return `${diffYears} year(s)`
  }, [currentMilestone])

  return (
    <div className={styles.milestoneDetailsWrapper}>
      <div className={styles.status} data-testid="milestone-status">
        <StateLabel
          className={styles.milestoneStatus}
          variant="small"
          status={currentMilestone.closed ? 'issueClosed' : 'issueOpened'}
        >
          {currentMilestone.closed ? LABELS.milestoneClosed : LABELS.milestoneOpen}
        </StateLabel>
        <div className={styles.milestoneDataContainer}>
          {totalOverDue ? (
            <div className={styles.milestoneData}>
              <div className={styles.overDue}>
                <AlertFillIcon size={12} />
                <span>
                  {LABELS.milestoneOverdue} {totalOverDue}
                </span>
              </div>
              <span className={styles.middot}>{LABELS.separator}</span>
            </div>
          ) : null}
          <div className={styles.milestoneData}>
            {currentMilestone.dueOn ? (
              <span>
                {LABELS.dueBy} {formattedDate}
              </span>
            ) : (
              <span>{LABELS.noDueDate}</span>
            )}
            <span className={styles.middot}>{LABELS.separator}</span>
          </div>
          <span>
            {currentMilestone.closed ? <>{LABELS.milestoneClosed}</> : <>{LABELS.milestoneLastUpdated}</>}
            <RelativeTime date={new Date(currentMilestone.updatedAt)} tense="past" />
          </span>
        </div>
      </div>
      {currentMilestone.description && currentMilestone.descriptionHTML ? (
        <div>
          <MarkdownViewer
            className={styles.mdViewer}
            markdownValue={currentMilestone.description}
            verifiedHTML={currentMilestone.descriptionHTML as SafeHTMLString}
            onChange={() => {}}
          />
        </div>
      ) : null}
      <div className={styles.progressSection}>
        <span>
          <span className={styles.progressPercentage}>{Math.floor(currentMilestone.progressPercentage)}%</span> complete
        </span>
        <ProgressBar progress={Math.floor(currentMilestone.progressPercentage)} aria-hidden="true" />
      </div>
    </div>
  )
}

try{ MilestoneDetail.displayName ||= 'MilestoneDetail' } catch {}