/**
 * @generated SignedSource<<d8833da20b720c3cadf5538eb35c4ca1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type OpenClosedMilestoneIssues$data = {
  readonly closedIssueCount: number | null | undefined;
  readonly openIssueCount: number | null | undefined;
  readonly " $fragmentType": "OpenClosedMilestoneIssues";
};
export type OpenClosedMilestoneIssues$key = {
  readonly " $data"?: OpenClosedMilestoneIssues$data;
  readonly " $fragmentSpreads": FragmentRefs<"OpenClosedMilestoneIssues">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OpenClosedMilestoneIssues",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "openIssueCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "closedIssueCount",
      "storageKey": null
    }
  ],
  "type": "SearchResultItemConnection",
  "abstractKey": null
};

(node as any).hash = "a5b9d884fd696edb829b218fa33fd663";

export default node;
